
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { titulacionesTodas } from "@/core/data/titulaciones";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, ProfesionalesFormulariosModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "formularios-profesionales-list",
  components: { "download-excel": JsonExcel, Page },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(ProfesionalesFormulariosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      codigo: t("csv.solicitudes.profesionales.codigo"),
      createdOnCsv: t("csv.solicitudes.profesionales.createdOn"),
      dni: t("csv.solicitudes.profesionales.dni"),
      nombre: t("csv.listados.profesionales.nombre"),
      apellidos: t("csv.listados.profesionales.apellidos"),
      telefono: t("csv.listados.profesionales.telefono"),
      email: t("csv.listados.profesionales.email"),
      estado: t("csv.solicitudes.profesionales.estado"),
      fechaNacimientoCsv: t("csv.listados.profesionales.fechaNacimiento"),
      direccion: t("csv.listados.profesionales.direccion"),
      localidad: t("csv.listados.profesionales.localidad"),
      provincia: t("csv.listados.profesionales.provincia"),
      titulacionText: t("csv.solicitudes.profesionales.titulacion"),
      titulacionOtras: t("csv.solicitudes.profesionales.titulacionOtras"),
      carnetConducir: t("csv.listados.profesionales.carnetConducir"),
      vehiculo: t("csv.listados.profesionales.vehiculo"),
    };
    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "codigo",
      "createdOnCsv",
      "dni",
      "nombre",
      "apellidos",
      "telefono",
      "email",
      "fechaNacimientoCsv",
      "direccion",
      "localidad",
      "provincia",
      "titulacionText",
      "titulacionOtras",
      "carnetConducir",
      "vehiculo",
      "estado",
    ];
    const csvName = "profesionales" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const error = computed(() => module.errors);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchFormularios(comarcaId);
        search.value = "";
        currentPage.value = 1;
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.profesionales"), [
        t("menu.solicitudes"),
      ]);
    });

    const handleEdit = (index, row) => {
      router.push(`profesionales/${row.id}`);
    };

    const profesionales = computed(() => {
      // NOTA: Necesario para exportar los campos
      return module.profesionalesFormularios?.map((ele) => {
        return {
          ...ele,
          nombre: ele.datosSolicitud.nombre,
          apellidos: ele.datosSolicitud.apellidos,
          solicitante: [
            ele.datosSolicitud.nombre,
            ele.datosSolicitud.apellidos,
          ].join(" "),
          telefono: ele.datosSolicitud.telefono,
          email: ele.datosSolicitud.email,
          createdOnCsv: new Date(ele.createdOn),
          fechaNacimientoCsv: new Date(ele.datosSolicitud.fechaNacimiento),
          direccion: ele.datosSolicitud.direccion,
          localidad: ele.datosSolicitud.localidad,
          provincia: ele.datosSolicitud.provincia,
          carnetConducir: ele.datosSolicitud.carnetConducir,
          vehiculo: ele.datosSolicitud.vehiculoPropio,
          titulacion: ele.datosSolicitud.titulacion.map((titulacion) => {
            return (
              titulacionesTodas.find((ele) => ele.id === titulacion)?.text ??
              titulacion
            );
          }),
          titulacionText:
            ele.datosSolicitud.titulacion
              ?.map((t) => titulacionesTodas.filter((a) => a.id == t))
              ?.map((t) => t.map((r) => r.text))
              .join(", ") || "",
          titulacionOtras: ele.datosSolicitud.titulacionOtras,
          nombreComarca: ele.comarca?.nombre,
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      (profesionales.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.solicitante, search.value) ||
            textSearch(data.datosSolicitud?.dni, search.value) ||
            textSearch(data.datosSolicitud?.email, search.value) ||
            textSearch(data.datosSolicitud?.telefono, search.value) ||
            textSearch(data.estado, search.value)
          );
        })
    );

    const { result, currentPage, lastPage } = useArrayPagination(
      filteredCollection,
      { pageSize: 100 }
    );

    return {
      profesionalesFormularios: filteredCollection,
      cargaListado,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      FormularioEstado,
      search,
      toLocaleDateString,
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
    };
  },
});
